if ( window.wp && window.wp.ajax ) {
    const { registerBlockType } = window.wp.blocks;

    const { 
        InnerBlocks
    } = window.wp.editor;

    registerBlockType('custom-gutenberg/nav-tabs', {
        // built-in attributes
        title: 'Nav Tabs',
        description: 'Nav Tabs',
        icon: 'slides',
        category: 'tabs',
        example: {},

        edit () {
            return (
                <nav>
                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                        <InnerBlocks allowedBlocks={ [ 'custom-gutenberg/tab-button' ] } template={ [ [ 'custom-gutenberg/tab-button', {} ] ] } />
                    </div>
                </nav>
            );
        },

        save () {
            return ( 
                <nav>
                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                        <InnerBlocks.Content />
                    </div>
                </nav>
            );
        },
    });
}