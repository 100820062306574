const locationMap = {
    
    $modal: null,
    $modalTitle: null,
    $map: null,
    map: null,
    marker: null,
    data: null,
    zoom: 15,

    setZoom(zoom) {
        locationMap.zoom = +zoom || locationMap.zoom || 15;
    },

    init() {

        if ( !locationMap.$modal ) {
            locationMap.$modal = $(`
                <div class="modal-location modal fade" tabindex="-1" aria-labelledby="modalLocationLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title"></h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body p-0">
                                <div class="map w-100 h-100"></div>                
                            </div>
                        </div>
                    </div>
                </div>
            `).appendTo('body');
            locationMap.$modalTitle = locationMap.$modal.find('.modal-title');
            // click on obj link prepares object data and triggers modal show
            $(document).on('click', '.btn-location', locationMap.prepareData);
            // on modal shown - init/reposition gmap
            locationMap.$modal.on('shown.bs.modal', locationMap.showGmap);
            
        }

        
    },

    prepareData(e) {

        e.preventDefault();

        // get object data and show modal        
        const $target = $(e.currentTarget);
        locationMap.data = {
            title: $target.attr('data-objecttitle') || '',
            lat: +$target.attr('data-lat') || 0,
            lng: +$target.attr('data-lng') || 0
        };       
        locationMap.$modal.modal('show');

    },

    // on modal show
    showGmap() {
        locationMap.$modalTitle.text(locationMap.data.title);
        
        loadGoogleMaps().done(() => {
            const position = new google.maps.LatLng(locationMap.data.lat, locationMap.data.lng);

            // register map once
            if ( !locationMap.map ) {
                locationMap.map = new google.maps.Map(locationMap.$modal.find('.map')[0], {
                    zoom: locationMap.zoom,                    
                    gestureHandling: 'greedy',
                    mapTypeControl: false,
                    styles: [{
                        featureType: 'poi.business',
                        elementType: 'labels',
                        stylers: [
                            { visibility: 'off' }
                        ]
                    }]
                });
            }            
            // register marker once
            if ( !locationMap.marker ) {
                locationMap.marker = new google.maps.Marker({
                    map: locationMap.map,
                    icon: {
                        path: google.maps.SymbolPath.CIRCLE,
                        scale: 10,
                        fillColor: "#4C76DB",
                        fillOpacity: 0.6,
                        strokeWeight: 0
                    },
                });
            }
            
            // center marker and map on new position
            locationMap.marker.setPosition(position);
            locationMap.map.setCenter(position);
            locationMap.map.setZoom(locationMap.zoom);

        });

    }

}

locationMap.init();