const dNone = 'd-none';
let id = 0;
const instance = {};

class InputGuests {
    constructor(elem) {
        this.id = ++id;
		instance[this.id] = this;

        this.$elem = $(elem);

        this.max = this.$elem.attr('data-max');
        this.adults = 0;

        this.placeholder = this.$elem.attr('data-placeholder');
        this.adultsLabel = this.$elem.attr('data-adultslabel');
        this.childrenLabel = this.$elem.attr('data-childrenlabel');

        this.$value = this.$elem.find('.input-guests-value');
        this.$dropdown = this.$elem.closest('[id^=dropdown-guests]');
        this.$dropdownMenu = this.$dropdown.find('.dropdown-menu');
        this.$menu = this.$dropdown.find('.dropdown-menu-popover');
        this.$hidden = this.$dropdown.find('input[type="hidden"]');
        this.$apply = this.$dropdown.find('.btn-apply');

        this.$adults = this.$dropdown.find('.input-adults');
        this.$children = this.$dropdown.find('.input-children');

        // events
        // open
        this.$dropdown.on('show.bs.dropdown', e => {
            this.values = this.$hidden[0].value || '0,0';
            this.setInputs(this, this.values);
        });

        this.$dropdownMenu.on('click.bs.dropdown', e => {
            e.stopPropagation();
            e.preventDefault();
        });

        // elem change
        this.$hidden.on('change', e => this.elemChange(this));
        // init set
        this.elemChange(this);

        $('[data-update]').on('click', e => {
            e.stopPropagation();
            const $target = $(e.currentTarget);
            const name = $target.attr('data-update');
            const direction = +$target.attr('data-direction');
            
            const $adults = this.$adults;
            const $children = this.$children;

            if(name == 'adults') {
                let adults = +$adults.attr('data-value') + direction;
        
                $adults.attr('data-value', adults);
                $adults.text(adults);
            } else if (name == 'children') {
                let children = +$children.attr('data-value') + direction;
        
                $children.attr('data-value', children);
                $children.text(children);
            }
            
            this.checkUpdateBtns(this, name);
            if(+$adults.attr('data-value') == 0) {
                $children.attr('data-value', 0);
                $children.text('0');
            }
            this.setInputs(this);

        });

        this.$apply.on('click', e => {
            
            let invalid = false;

            if(! this.$dropdown.find('.children-wrap').hasClass(dNone)) {
                this.$dropdown.find('.input-child[required]').each((i, child) => {
                    let childInvalid = $(child).is(':invalid');
                    invalid = childInvalid ? childInvalid : invalid;
                });
            }
            
            if(! invalid) {
                this.setValues(this);
                this.$elem.dropdown('hide');
            }
        });

        this.$elem.on('hide.bs.dropdown', e => {

            if (e.clickEvent) {

                let invalid = false;

                if(! this.$dropdown.find('.children-wrap').hasClass(dNone)) {
                    this.$dropdown.find('.input-child[required]').each((i, child) => {
                        let childInvalid = $(child).is(':invalid');
                        invalid = childInvalid ? childInvalid : invalid;
                    });
                }

                if(! invalid) {
                    this.setValues(this);
                    this.$elem.dropdown('hide');
                } else {
                    e.preventDefault();
                }

            }
        })

    }

    checkUpdateBtns(instance, name) {
        const value = instance.$dropdown.find('.input-'+name).attr('data-value');
        const [min, max] = this.getControlMinMax(instance, name);
        instance.$dropdown.find(`[data-update="${name}"][data-direction="-1"]`).prop('disabled', value == min);
        instance.$dropdown.find(`[data-update="${name}"][data-direction="1"]`).prop('disabled', value == max);
    }

    getControlMinMax(instance, name) {
        let min = name == 'adults' ? instance.adults : 0;
        let max = instance['max' + name[0].toUpperCase() + name.slice(1)] || instance.max;
        let computedMax = instance.max - (instance.adults || 0) - (instance.children || 0) - (instance.infants || 0);
        computedMax = instance[name] + computedMax;
        max = max > computedMax ? computedMax : max;
        return [min, max];
    }

    elemChange(instance) {
        // set initial data
        instance.values = instance.$hidden[0].value;
        // set element guests number
        let adults = 0;
        let kids = 0;
        let text = instance.placeholder;

        if(instance.values && instance.values != '0,0') {
            let value = instance.values.split(',');
            adults = value[0];
            kids = value[1];
            text = adults + ' ' + instance.adultsLabel + ', ' + kids + ' ' + instance.childrenLabel;
        }

        instance.$value.text(text);
    }

    setInputs(instance, guests = null){
        const $adults = instance.$adults;
        const $children = instance.$children;
        const $childrens = instance.$dropdown.find('.children-wrap');
        const $childs = $childrens.find('.input-child');

        const value = guests ? guests.split(',') : null;
        
        const adults = value ? +value[0] : +$adults.attr('data-value');
        const children = value ? +value[1] : +$children.attr('data-value');

        $adults.attr('data-value', adults);
        $adults.text(adults);

        $children.attr('data-value', children);
        $children.text(children);

        if ( adults && children ) {
            $childrens.removeClass(dNone);
            $childs.each((i, child) => {
                let $wrapper = $(child).parent('.input-child-wrapper');
                if ( i + 1 <= children ) {
                    child.classList.remove(dNone);
                    $wrapper.removeClass(dNone);
                    $(child).prop('hidden', false);
                    $(child).prop('required', true);
                } else {
                    child.classList.add(dNone);
                    $wrapper.addClass(dNone);
                    $(child).prop('hidden', true);
                    $(child).prop('required', false);
                }
            });
        } else {
            $childrens.addClass(dNone);
        }

        this.checkUpdateBtns(instance, 'adults');
        this.checkUpdateBtns(instance, 'children');

        instance.$dropdown.find('[data-update="children"][data-direction="1"]').prop('disabled', adults == 0 || adults == instance.max);
        instance.$dropdown.find('[data-update="adults"][data-direction="1"]').prop('disabled', adults + children == instance.max);
        instance.$dropdown.find('[data-update="children"][data-direction="1"]').prop('disabled', adults + children == instance.max);
    }

    setValues(instance) {
        let adults = 0;
        let kids = 0;

        const $adults = instance.$dropdown.find('.input-adults');
        const $children = instance.$dropdown.find('.input-children');

        const value = [+$adults.attr('data-value'), +$children.attr('data-value')];
        adults = value[0];
        kids = value[1];

        instance.$dropdown.find('.input-child').map((i, child) => {
            i < value[1] && value.push(+child.value || 0);
        });
        let values = value.join(',');

        instance.$value.text(instance.$value.text(adults + ' ' + instance.adultsLabel + ', ' + kids + ' ' + instance.childrenLabel));
        instance.$hidden[0].value = values;
        instance.$hidden.change();

        //instance.$elem.dropdown('hide');
    }
}

export {
	InputGuests
};
