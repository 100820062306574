import functions from '../functions.js';

$(function () {
    // global datepicker init with automatic "dateTo" set if provided
    let displayDate = new Date('2019-06-01');
    let now = functions.cleanDate(new Date);
    if ( now > displayDate ) {
        displayDate = now;
    }

    $('.datepicker-range').each((i, elem) => {
        const $datepicker = $(elem);
        const minDate = $datepicker.attr('data-min');
        const maxDate = $datepicker.attr('data-max');
        const arrival = $datepicker.attr('data-arrival') ? $datepicker.attr('data-arrival') : [];
        const defaultDate = $datepicker.attr('data-defaultDate') ? $datepicker.attr('data-defaultDate') : [];
        const duration = Number($datepicker.attr('data-duration'));
        $datepicker.flatpickr({
            mode: "range",
            showMonths: functions.isMobile() ? 1 : 2,
            altInput: true,
            wrap: true,
            altFormat: "j.m.Y",
            dateFormat: "Y-m-d",
            defaultDate: defaultDate,
            minDate: minDate && minDate >= now ? minDate : now,
            maxDate: maxDate ? maxDate : null,
            disableMobile: true,
            prevArrow: '<i class="la la-arrow-circle-left la-fw la-2x pr-2"></i>',
            nextArrow: '<i class="la la-arrow-circle-right la-fw la-2x pl-2"></i>',
            locale: {
                rangeSeparator: ' - '
            },
            onDayCreate(dObj, dStr, fp, dayElem){

                let to = new Date(maxDate);
                let weekday =  new Date(dayElem.dateObj).getDay();
                weekday = '' + (weekday ? weekday : 7);
                to.setHours(0, 0, 0, 0);

                if ( dayElem.dateObj > to || (arrival.length && $.inArray( weekday , arrival ) == -1) ) {
                    dayElem.classList.add('unselectable');
                }
            }
        });
        // to style clear toggler
        $datepicker.on('change', e => requestAnimationFrame(() => $(e.target).parent().toggleClass('datepicker-value', !!e.target.value)));
    });
});