if ( window.wp && window.wp.ajax ) {
    const { registerBlockType } = window.wp.blocks;

    const { 
        InspectorControls,
        InnerBlocks
    } = window.wp.editor;
    
    const { 
        PanelBody, 
        TextControl,
        CheckboxControl
    } = window.wp.components;

    const {
        useState
    } = window.wp.element;

    registerBlockType('custom-gutenberg/tab-pane', {
        // built-in attributes
        title: 'Tab Pane',
        description: 'Tab',
        icon: 'slides',
        category: 'tabs',
        example: {},
        parent: ['custom-gutenberg/nav-content'],

        // custom attributes
        attributes: {
            id: {
                type: 'string',
                default: '',
            },
            active: {
                type: 'boolean',
                default: false,
            },
        },

        edit ({ attributes, setAttributes }) {

            const {
                id,
                active
            } = attributes;

            // custom functions
            const [ isCheched, setChecked ] = useState( false );
            setAttributes({active: isCheched});

            function onIdChange (id) {
                setAttributes({id: id});
            };

            return ([
                <InspectorControls style={ { marginBottom: '40px' } }>
                    <PanelBody title={ 'Tab settings' }>
                        <p><strong>ID must be same as Tab button!</strong></p>
                        <TextControl value={ id } onChange={ onIdChange }></TextControl>
                        <CheckboxControl
                            label="Is default active tab"
                            help="Only ONE tab can be default active!"
                            checked={ isCheched }
                            onChange={ setChecked  }
                        />
                    </PanelBody>
                </InspectorControls>,
                <div class={ "tab-pane fade" + (active ? "show active" : '') } id={ id } role="tabpanel">
                    <InnerBlocks />
                </div>
            ]);
        },

        save ({ attributes }) {

            const {
                id,
                active
            } = attributes;

            return ( 
                <div class={ "tab-pane fade" + (active ? "show active" : '') } id={ id } role="tabpanel">
                    <InnerBlocks.Content />
                </div>
            );
        },
    });
}