import functions from '../functions';
import Slider from "../components/Slider";
//import listResults from './components/listResults.js';
import pagination from '../components/pagination';

const listMap = {
    $map: null,
    map: null,
    markers: [],
    params: {},
    locations: {},
    objectWindow: null,
    init(params, defaults = {}) {
        this.params = params;
        this.defaults = defaults;

        const $window = $(window);
        listMap.$map = $('.list-map');
        listMap.$form = $('form.search-map');
        listMap.form = listMap.$form[0];
        
        listMap.$form.on('change', e => {
            e.preventDefault();
            if ( listMap.stopListeners ) {
                return;
            }
            const $target = $(e.currentTarget);
            
            listMap.params = $.extend(listMap.params, listMap.defaults, functions.getFormData($target));
            listMap.params._page = 1;
            listMap.setDestination(listMap.params.destination);
            
            delete listMap.params.coordinates;
            /*if(! listMap.params.destination) {
            }*/

            //functions.setUrlData(listMap.params, true);
            listMap.mapFind();
            //listMap.find();
        });

        if(listMap.$map.length) {

            listMap.map = new google.maps.Map(listMap.$map[0], {
                zoom: 12,
                //center: { lat: 45.12740978317192, lng: 13.902139984375038 },
                center: { lat: 44.06695852385783, lng: 15.360575126632485 },
                gestureHandling: 'greedy',
                mapTypeControl: false,
                styles: [{
                    featureType: 'poi.business',
                    elementType: 'labels',
                    stylers: [
                        { visibility: 'off' }
                    ]
                }]
            });

            // init info window
            listMap.objectWindow = new google.maps.InfoWindow({
                maxWidth: 300,
                content: ''
            });

            //listMap.find();
            //listMap.mapFind();

            // only trigger mapfind when zoomout or dragend, zoomin we already have data
            listMap.map.addListener('zoom_changed', () => {
                listMap.hideMarkers();
                if ( !listMap.suppressMapEvents ) {
                    listMap.lastEvent = 'zoom_changed';
                    listMap.params._page = 1;
                } else {
                    listMap.lastEvent = null;
                }
            });
            listMap.map.addListener('dragend', () => {
                listMap.hideMarkers();
                if ( !listMap.suppressMapEvents ) {
                    listMap.lastEvent = 'dragend';
                    listMap.params._page = 1;
                } else {
                    listMap.lastEvent = null;
                }
            });
            listMap.map.addListener('bounds_changed', () => {
                if ( listMap.suppressMapEvents ) {
                    setTimeout(() => {
                        listMap.suppressMapEvents = false;
                        listMap.showMarkers();
                    });
                }
            });
            listMap.map.addListener('idle', () => {               
                if (!listMap.lastEvent) {
                    return;
                }
                const bounds = listMap.map.getBounds();
                const sw = bounds.getSouthWest();
                const nw = bounds.getNorthEast();
                listMap.params.coordinates = sw.lat() + ',' + nw.lat() + ',' + sw.lng() + ',' + nw.lng();
                listMap.find();
            });

        }

        $('.map-list-items').on('click', '.btn-location', e => {            
            e.preventDefault();
            e.stopPropagation();
            const $this = $(e.currentTarget);
            listMap.showObjectOnMap(+$this.attr('data-publicId'));
        });

        return listMap.map;
    },
    // Sets the map on all markers in the array.
    setMapOnAll(map) {
        for (let i = 0; i < listMap.markers.length; i++) {
            listMap.markers[i].setMap(map);
        }
    },
    // Removes the markers from the map, but keeps them in the array.
    hideMarkers() {
        listMap.objectWindow.close();
        listMap.setMapOnAll(null);
    },
    // Shows any markers currently in the array.
    showMarkers() {
        listMap.setMapOnAll(listMap.map);
    },
    mapFind(params) {

        if ( params ) {
            listMap.params = Object.assign({}, params);
            delete listMap.params.coordinates;
            listMap.stopListeners = true;
            functions.setFormData(listMap.form, listMap.params);
            listMap.stopListeners = false;
        }
       
       /*
        if( !listMap.params.coordinates && !listMap.params.destination) {
            listMap.params.destination = '98';
            listMap.params.countryId = '98';
        }*/

        listMap.hideMarkers();
        listMap.markers = [];
        listMap.bounds = new google.maps.LatLngBounds();
        
        $.get('/services/map/', listMap.params).then(response => {
            listMap.locations = JSON.parse(response);

            // init each marker
            listMap.locations.map(location => {
                const marker = new google.maps.Marker({
                    map: listMap.map,
                    position: new google.maps.LatLng(location.lat, location.lng),
                    clickable: true,
                    /*icon: new google.maps.MarkerImage(
                        //"/wp-content/themes/theme/dist/marker_blue.svg",
                        '',
                        null, 
                        null, 
                        null, 
                        new google.maps.Size(18, 18)
                    )*/
                });
                marker.publicId = location.publicId;
                
                marker.addListener('click', e => {
                    listMap.objectWindow.setContent('');
                    listMap.objectDetails(marker.publicId);
                    listMap.objectWindow.open({
                        anchor: marker,
                        map: listMap.map,
                        shouldFocus: false,
                    });
                });

                listMap.markers.push(marker);

                listMap.bounds.extend(marker.getPosition());

            });            
            listMap.map.fitBounds(listMap.bounds);
        });
    },
    find() {
        
        listMap.setLoadingMode();
        listMap.params.template = 'accommodationMap';
        $.get('/services/list/', listMap.params).then(response => {
            response = JSON.parse(response);

            listMap.unsetLoadingMode();

            const total = response.total;
            
            $('.map-list-items').html(response.html);
            if (total != 0) {

                $('.map-paginate').html(pagination({
                    currentPage: listMap.params._page,
                    total: total,
                    perPage: listMap.params.perPage,
                }));
                $('.map-paginate').removeClass('d-none');
            } else {
                $('.map-paginate').addClass('d-none');
            }

            // LOZAD
            window.observer.observe();

            //listResults.getResults(total, listMap.params._page, listMap.params.perPage);

            listMap.initComponents();
            listMap.showMarkers();
        });

    },
    setLoadingMode() {
        if(! listMap.loading) {
            listMap.loading = true;

            if ( ! $('.map-spinner').length) {
                $('<div class="map-spinner d-none mt-5"><div class="spinner-border text-secondary" role="status" style="width: 4rem; height: 4rem;"><span class="sr-only">Loading...</span></div></div>').insertBefore('.map-list-items');
            }

            $('.map-spinner').removeClass('d-none');
            $('.map-spinner').addClass('d-flex justify-content-center');
            $('.map-list-items').addClass('d-none');
            $('.map-paginate').addClass('d-none');
        }
    },
    unsetLoadingMode() {
        if(listMap.loading) {
            listMap.loading = false;

            $('.map-spinner').removeClass('d-flex justify-content-center');
            $('.map-spinner').addClass('d-none');
            $('.map-list-items').removeClass('d-none');
            $('.map-paginate').removeClass('d-none');

            //functions.scrollToElement(list.$listItems, 100);
        }
    },
    initComponents() {
        
        // destroy old instances
        if ( listMap.sliders && listMap.sliders.length ) {
            listMap.sliders.forEach(slider => {
                slider.instance.destroy();
            });
        } 
           
        listMap.sliders = [];
        $('[id^=list-slider]').each((i, elem) => {
            const length = $(elem).find('.keen-slider__slide').length;
            listMap.sliders[i] = new Slider('#' + $(elem).attr('id'), {
                rubberband: false,
                loop: true,
                arrows: true,
                slidesPerView: 1,
                spacing: 0,
                duration: 1000,
                autoplay: 0,
                pauseOnHover: true,
                pager: false,
            });
        });

        /*$('.map-list-items .btn-location').each((index, btn) => {
            const $btn = $(btn);
            $btn.attr('data-toggle', false);
            $btn.attr('data-target', false);
        });

        $('.map-list-items .btn-location').on('click', e => {
            e.preventDefault();
            const $target = $(e.currentTarget);
            const publicId = $target.attr('data-publicid');
            const marker = listMap.markers.filter(marker => {
                if(marker.publicId == publicId) {
                    return marker;
                }
            })[0];
            //const marker = listMap.markers.filter(marker => marker.publicId === publicId);
            listMap.map.setCenter(marker.position);
            listMap.map.setZoom(15);
            google.maps.event.trigger(marker, 'click');
        });*/

        // register paginate        
        $('.map-paginate [data-page]').on('click', e => {
            e.preventDefault();
            listMap.paginate(+$(e.currentTarget).attr('data-page'));
        });
    },
    objectDetails(publicId) {
        const params = {
            publicId: publicId,
            dateFrom: listMap.params.dateFrom,
            dateTo: listMap.params.dateTo,
            persons: listMap.params.guests,
            language: functions.getLang(),
            template: 'map'
        };

        $.get('/services/details/', params).then(response => {
            //if(response.status) {}
            listMap.objectWindow.setContent(response.view);
        });
    },
    setDestination(destination) {

        if(destination) {
            const destinations = destination.split(',');

            let countryIds = [],
                regionIds = [],
                rivieraIds = [],
                placeIds = [];

            destinations.forEach(destination => {
                const dest = destination.split(':');
                
                listMap.params.countryId = dest[0] ? listMap.getDestinationIds(dest[0], countryIds) : null;
                listMap.params.regionId = dest[1] ? listMap.getDestinationIds(dest[1], regionIds) : null;
                listMap.params.rivieraId = dest[2] ? listMap.getDestinationIds(dest[2], rivieraIds) : null;
                listMap.params.placeId = dest[3] ? listMap.getDestinationIds(dest[3], placeIds) : null;
            });
        } else {
            listMap.params.countryId = null;
            listMap.params.regionId = null;
            listMap.params.rivieraId = null;
            listMap.params.placeId = null;
        }
    },
    getDestinationIds(destination, array) {
        if(destination && $.inArray(destination, array)) {
            array.push(destination);
        }
        return array.join();
    },
    paginate(page) {
        if (page != listMap.params._page) {
            listMap.params._page = page;
        }

        //functions.setUrlData(listMap.params, true);
        listMap.find();
    },
    showObjectOnMap(publicId) {
        listMap.suppressMapEvents = true;
        const marker = listMap.markers.filter(marker => marker.publicId === publicId)[0];
        listMap.map.setCenter(marker.position);
        listMap.map.setZoom(15);
        google.maps.event.trigger(marker, 'click'); 
    }
};

module.exports = {
    listMap: listMap,
};