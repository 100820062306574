import functions from '../functions.js';

$(function () {
    /* gallery */
    const lg = {};
    window.showGallery = (index, id) => {
        index = +index || 0;
        if ( lg[id] ) {
            lg[id].index = index;
            lg[id].build(index);
        } else {
            let $elem = $('[data-images-' + id + ']');
            lg[id] = $elem.lightGallery({
                constrols: true,
                dynamic: true,
                thumbnail: functions.isMobile() ? false : true,
                toogleThumb: true,
                thumbWidth: 130,
                thumbMargin: 10,
                download: false,
                fullScreen : true,
                preload: 2,
                index: index,
                dynamicEl: JSON.parse($elem.attr('data-images-' + id)),
            }).data('lightGallery');
        }
    };
    $('[data-gallery-id]').on('click', function (e) {
        e.preventDefault();
        let galleryId = $(this).attr('data-gallery-id');
        galleryId && showGallery($(this).attr('data-index'), galleryId);
    });
    // don't focus div with gallery
    $('[data-gallery-id]').on('focus', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();
        return false;
    });
    /* end gallery */
});