if ( window.wp && window.wp.ajax ) {
    const { registerBlockType } = window.wp.blocks;

    const { 
        InnerBlocks
    } = window.wp.editor;

    registerBlockType('custom-gutenberg/tab-content', {
        // built-in attributes
        title: 'Tab Content',
        description: 'Tab Content',
        icon: 'slides',
        category: 'tabs',
        example: {},

        edit () {
            return (
                <div class="tab-content" id="nav-tabContent">
                    <InnerBlocks allowedBlocks={ [ 'custom-gutenberg/tab-pane' ] } template={ [ [ 'custom-gutenberg/tab-pane', {} ] ] } />
                </div>
            );
        },

        save () {
            return ( 
                <div class="tab-content" id="nav-tabContent">
                    <InnerBlocks.Content />
                </div>
            );
        },
    });
}