import functions from './functions.js';

import Slider from "./components/Slider";
import { InputGuests } from "./components/input-guests.js";
import inputPopover from "./components/input-popover.js";
import "./components/gallery.js";
import "./components/datepicker.js";
import "./components/select2.js";
import {FormGuide, FormGuideDefaults} from "./components/form-guide.js";

$(function() {

    $('.unit-modal').on('click', e => {
        const $target = $(e.currentTarget);
        $('[id=' + $target.attr('data-bs-tab') +']').tab('show');
    });

    $('.input-guests').each((i, elem) => {
        elem._inputGuests = new InputGuests(elem);
        //console.log(elem._inputGuests);
    });

    if(functions.isMobile()) {
        $('.show-on-desktop').remove();
        $('.hide-on-desktop').removeClass('d-none');
    } else {
        $('.show-on-desktop').removeClass('d-none');
        $('.hide-on-desktop').remove();
    }

    $('#collapseItinerary').on('show.bs.collapse', e => {
        $('.show-itinerary').addClass('d-none');
    });
    $('#collapseItinerary').on('hide.bs.collapse', e => {
        $('.show-itinerary').removeClass('d-none');
    });

    $('.form-guide').each((i, elem) => {
		new FormGuide(elem);
	});

    let lastScroll = window.scrollY;
    const $mainHeader = $('.main-header');
    const $navMain = $('.nav-main');

    if(! $('.page-type-details')[0]) {
        $(window).on('scroll', e => {
            $mainHeader.toggleClass('show', window.scrollY < lastScroll);
            $mainHeader.toggleClass('hide', window.scrollY > lastScroll);
            window.scrollY == 0 && $mainHeader.toggleClass('show', false);
            lastScroll = window.scrollY;
        });
    }

    $('#navMainMenu').on('show.bs.collapse', e => {
        $mainHeader.addClass('opened');
        $navMain.addClass('opened');
    });
    $('#navMainMenu').on('hide.bs.collapse', e => {
        $navMain.removeClass('opened');
    });

    //inputGuests.apply();
    inputPopover.apply();

    if($('#hero-slider')[0]) {
        new Slider($('#hero-slider'), {
            rubberband: false,
            loop: true,
            arrows: true,
            pager: false,
            autoplay: 5000,
            //autoplay: 0,
            slides: {
                perView: 1,
                spacing: 0,
            },
            defaultAnimation: {
                duration: 1200,
            },
            /*detailsChanged: (s) => {
              s.slides.forEach((element, idx) => {
                element.style.opacity = s.track.details.slides[idx].portion
              })
            },*/
            //renderMode: "custom",
        });
    }

    let postSlider = [];
    $('[id^=post-slider]').each((i, elem) => {
        const length = $(elem).find('.keen-slider__slide').length;
        postSlider[i] = new Slider('#' + $(elem).attr('id'), {
            rubberband: false,
            loop: false,
            arrows: true,
            pager: false,
            slides: {
                perView: 4.2,
                spacing: 20,
            },
            breakpoints: {
                '(max-width: 768px)': {
                    slides: {
                        perView: 1.2,
                        spacing: 15,
                    },
                },
            }
        });
    });

    if(functions.isMobile()) {

        $('.keen-slider-mobile').addClass('keen-slider');
        $('.keen-slider-mobile').removeClass('row');
        $('.keen-slider__slide-mobile').addClass('keen-slider__slide');

        let catalogueSlider = [];
        $('[id^=catalogue-slider]').each((i, elem) => {
            const length = $(elem).find('.keen-slider__slide').length;
            catalogueSlider[i] = new Slider('#' + $(elem).attr('id'), {
                rubberband: false,
                loop: false,
                arrows: false,
                pager: false,
                slides: {
                    //origin: "center",
                    perView: 1.2,
                    spacing: 8,
                }
            });
        });

        let navSlider = [];
        $('[id^=nav-slider]').each((i, elem) => {
            const length = $(elem).find('.keen-slider__slide').length;
            navSlider[i] = new Slider('#' + $(elem).attr('id'), {
                rubberband: false,
                loop: false,
                arrows: true,
                pager: false,
                slides: {
                    perView: 1.6,
                    spacing: 0,
                }
            });
        });

        $('[id^=nav-slider]').find('.nav-link').on('shown.bs.tab', e => {
            const $target = $(e.currentTarget);
            const sliderId = $target.parents('[id^=nav-slider]').attr('id');
            let targetId = $target.attr('data-bs-target').replace('#', '');

            navSlider.forEach(slider => {
                if(sliderId == slider.selector.replace('#', '')) {

                    slider.$slides.each((index, slide) => {
                        if($(slide).find('.nav-link').attr('data-bs-target') == '#' + targetId) {
                            slider.instance.moveToIdx(index);
                        }
                    });

                }
            });

            let $slider = $('.tab-pane[id=' + targetId + ']').find('[id^=catalogue-slider]');

            new Slider($slider, {
                rubberband: false,
                loop: false,
                arrows: true,
                pager: false,
                slides: {
                    //origin: "center",
                    perView: 1.2,
                    spacing: 8,
                }
            });
        });
    } else {

        let catalogueSlider = [];
        $('[id^=catalogue-slider]').each((i, elem) => {
            const length = $(elem).find('.keen-slider__slide').length;
            catalogueSlider[i] = new Slider('#' + $(elem).attr('id'), {
                rubberband: false,
                loop: false,
                arrows: ! functions.isMobile(),
                pager: false,
                slides: {
                    //origin: "center",
                    perView: 3.2,
                    spacing: 8,
                },
                breakpoints: {
                    '(max-width: 768px)': {
                        arrows: false,
                        slides: {
                            perView: 1.2,
                            spacing: 15,
                        },
                    },
                }
            });
        });

    }

    $('.search-form label').on('click', e => {
        const $target = $(e.currentTarget);
        const $elem = $target.find('.select2-search__field');
        $elem[0] && $elem.focus();
    });

    $(document).on('click', '[data-scroll-to]', e => {
        e.preventDefault();
        const $this = $(e.target);
        let scrollTo = $this.attr('data-scroll-to').split(',');
        functions.scrollToElement(scrollTo[0], scrollTo[1] || 0);
    });

    if(functions.getCookie('cookie_usage') !== 'accepted') {
        $("#cookie-bar").removeClass('d-none');
    }

    $('#cookie-bar-accept').on('click', function (e) {
        functions.setCookie('cookie_usage', 'accepted');
        $("#cookie-bar").addClass('d-none');
    });

});