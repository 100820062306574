import functions from "./functions.js";

$(function () {

    const $form = $('.newsletter-form');
    const form = $form[0];

    const lang = functions.getLang();
    let output = '';

    if (!form) {
        return;
    }

    $form.on('submit', e => {
        e.preventDefault();

        $('.newsletter-btn').addClass('d-none');
        $('.newsletter-spinner').removeClass('d-none');
        $('.newsletter-spinner').addClass('d-flex');

        let params = {};
        $.each($form.serializeArray(), function (i, field) {
            params[field.name] = field.value;
        });

        params['language'] = functions.getLang();

        grecaptcha.ready(function () {
            grecaptcha.execute('6Lecfu0gAAAAAIlqJvGCxjSsfUpzkc2HC42KLV_W', { action: 'contact' }).then(function (token) {
                params['gRecaptchaToken'] = token;
                params = functions.getDataForUrl(params);
                $.post('/services/mailchimp/', params).then(response => {
                    
                    response = JSON.parse(response);
                    $('#newsletter-modal').modal('show');
                    $('#newsletter-modal').find(".response-output").html(response.data);

                    $('.newsletter-btn').removeClass('d-none');
                    $('.newsletter-spinner').removeClass('d-flex');
                    $('.newsletter-spinner').addClass('d-none');

                    setTimeout(() => { $('#newsletter-modal').modal('hide') }, 4000);

                    if (response.status == true) {
                        form.reset();
                    }
                });
            });
        });

    });

});