if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;
    
    const { 
        RichText,
        InspectorControls,
        MediaUpload,
    } = window.wp.editor;
    
    const { 
        PanelBody, 
        IconButton, 
        TextControl
    } = window.wp.components;

    registerBlockType('custom-gutenberg/hero-slide', {
        // built-in attributes
        title: 'Hero Slide',
        description: 'Custom Hero Slide',
        icon: 'slides',
        category: 'hero-section',
        example: {},
        parent: ['custom-gutenberg/hero-slider'],

        // custom attributes
        attributes: {
            link: {
                type: 'string',
                default: '',
            },
            backgroundImage: {
                type: 'string',
                default: ''
            }, 
            tag: {
                type: 'string',
                default: ''
            },
            title: {
                type: 'string',
                default: ''
            },
            description: {
                type: 'string',
                default: ''
            },
            viewMore: {
                type: 'string',
                default: 'Saznajte više'
            },
        },

        // built-in functions
        edit({ attributes, setAttributes }) {

            const {
                link,
                backgroundImage,
                tag,
                title,
                description,
                viewMore
            } = attributes;

            // custom functions
            function onLinkChange (link) {
                setAttributes({link: link});
            };

            function onSelectImage (image) {
                setAttributes({backgroundImage: image.sizes.full.url});
            }

            function onTagChange(tag) {
                setAttributes({tag: tag});
            }

            function onTitleChange(title) {
                setAttributes({title: title});
            }

            function onDescriptionChange(description) {
                setAttributes({description: description});
            }

            function onViewMoreChange(viewMore) {
                setAttributes({viewMore: viewMore});
            }

            return ([
                <InspectorControls style={ { marginBottom: '40px' } }>
                    <PanelBody title={ 'Link' }>
                        <p><strong>Enter url: (ex: /putovanja)</strong></p>
                        <TextControl value={ link } onChange={ onLinkChange }></TextControl>
                    </PanelBody>
                    <PanelBody title={ 'Background Image Settings' }>
                        <MediaUpload 
                            onSelect={ onSelectImage }
                            type="image"
                            value={ backgroundImage } 
                            render={ ( { open } ) => (
                                <IconButton
                                    onClick={ open }
                                    icon="upload"
                                    classNames="editor-media-placeholder__button is-button is-default is-large">
                                    Select Background Image
                                </IconButton>
                            ) } />
                    </PanelBody>
                </InspectorControls>
                ,
                <a class="keen-slider__slide cover-image d-flex flex-column justify-content-end text-decoration-none" style={{ backgroundImage: `url(${backgroundImage})` }}>
                    <div class="container hero-content">
                        <div class="text-white py-5">
                            <h4 class="mb-2">
                                <RichText 
                                    key="editable" 
                                    tagName="span" 
                                    class="badge bg-danger bg-opacity-85 px-2 px-lg-4 py-2 py-lg-3"
                                    placeholder="Oznaka" 
                                    value={ tag }
                                    onChange={ onTagChange }
                                    style={ { color: '#fff' } } />
                            </h4>
                            
                            <RichText 
                                key="editable" 
                                tagName="h1" 
                                class="text-white text-shadow display-lg-1 mb-3"
                                placeholder="Title" 
                                value={ title }
                                onChange={ onTitleChange }
                                style={ { color: '#fff' } } />
                            <RichText 
                                key="editable" 
                                tagName="h4" 
                                class="text-white text-shadow"
                                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nec ornare sapien eu est sociis phasellus hendrerit quam semper." 
                                value={ description }
                                onChange={ onDescriptionChange }
                                style={ { color: '#fff' } } />
                            <RichText 
                                key="editable" 
                                tagName="div" 
                                class="btn btn-secondary text-upperacse text-shadow text-white fw-bold"
                                placeholder="Saznajte više" 
                                value={ viewMore }
                                onChange={ onViewMoreChange } />
                        </div>
                    </div>
                </a>
            ]
            );
        },

        save({ attributes }) {

            const {
                link,
                backgroundImage,
                tag,
                title,
                description,
                viewMore
            } = attributes;

            return (
                <a href={ link } class="keen-slider__slide lozad cover-image d-flex flex-column justify-content-end text-decoration-none" data-background-image={ backgroundImage }>
                    <div class="container hero-content">
                        <h4 class="mb-2"><span class="badge bg-danger bg-opacity-85 px-2 px-lg-4 py-2 py-lg-3">{ tag }</span></h4>
                        <h1 class="text-white text-shadow display-lg-1 mb-3">{ title }</h1>
                        <RichText.Content 
                            tagName="h4" 
                            class="text-white text-shadow"
                            value={ description } />
                        <div class="btn btn-secondary text-upperacse text-shadow text-white fw-bold">
                            { viewMore }
                        </div>
                    </div>
                </a>
            );
        },
    });
}