if ( window.wp && window.wp.ajax ) {
    const { registerBlockType } = window.wp.blocks;

    const { 
        InnerBlocks
    } = window.wp.editor;

    const ALLOWED_BLOCKS = ['custom-gutenberg/nav-tabs', 'custom-gutenberg/tab-content'];

    const MY_TEMPLATE = [
        [ 'custom-gutenberg/nav-tabs', {} ],
        [ 'custom-gutenberg/tab-content', {} ],
    ];

    registerBlockType('custom-gutenberg/hero-search', {
        // built-in attributes
        title: 'Hero Search',
        description: 'Tražilice produkta',
        icon: 'slides',
        category: 'hero-section',
        example: {},
        parent: ['custom-gutenberg/hero-section'],

        edit () {
            return (
                <div class="container hero-search pb-3 pb-lg-5">
                    <InnerBlocks allowedBlocks={ ALLOWED_BLOCKS } template={ MY_TEMPLATE } />
                </div>
            );
        },

        save () {
            return ( 
                <div class="container hero-search pb-3 pb-lg-5">
                    <InnerBlocks.Content />
                </div> 
            );
        },
    });
}