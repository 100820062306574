if ( window.wp && window.wp.ajax ) {
    const { registerBlockType } = window.wp.blocks;

    const { 
        InnerBlocks
    } = window.wp.editor;

    const ALLOWED_BLOCKS = [
        'custom-gutenberg/hero-slider',
        'custom-gutenberg/hero-search'
    ];

    const MY_TEMPLATE = [
        [ 'custom-gutenberg/hero-slider', {} ],
        [ 'custom-gutenberg/hero-search', {} ],
    ];

    registerBlockType('custom-gutenberg/hero-section', {
        // built-in attributes
        title: 'Hero Section',
        description: 'Custom Hero Section',
        icon: 'slides',
        category: 'hero-section',
        example: {},

        edit () {
            return (
                <section id="hero" class="home-hero position-relative bg-dark">
                    <InnerBlocks allowedBlocks={ ALLOWED_BLOCKS } template={ MY_TEMPLATE } />
                </section>
            );
        },

        save () {
            return ( 
                <section id="hero" class="home-hero position-relative bg-dark">
                    <InnerBlocks.Content />
                </section> 
            );
        },
    });
}