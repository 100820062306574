import './details/details';
import './details/details-travel';
import './details/details-accommodation';

$(() => {

    if($('[data-redirectURL]')[0]) {
        let url = $('[data-redirectURL]').attr('data-redirectURL');
        if(url) {
            setTimeout(() => {
                window.location = url;
            }, 5000);
        }
    }
    
    $('.btn-booking').on('click', e => {
        const $target = $(e.currentTarget);
        const unitId = $target.attr('data-unitid');

        let $alert = $('.alert.select-guests[data-unitid=' + unitId + ']')[0]
            ? $('.alert.select-guests[data-unitid=' + unitId + ']')
            : $('.alert.check-availability[data-unitid=' + unitId + ']');
        
        const guests = $('.availability-travel').find('[name=guests][data-unitid=' + unitId + ']')[0] 
            ? $('.availability-travel').find('[name=guests][data-unitid=' + unitId + ']').val()
            : $('.availability-form').find('[name=guests]').val();

        const dates = $('.availability-form').find('[name=dates]')[0]
            ? $('.availability-form').find('[name=dates]').val()
            : true;

        if( !guests || guests.startsWith('0,') || !dates || !$target.attr('data-href').split('?')[1]) {
            e.preventDefault();
            $alert.addClass('active');
            
            setTimeout(() => {
                $alert.removeClass('active');
            }, 3000);
        }
    });

});