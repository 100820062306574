import functions from '../functions.js';
import { details } from './details';

$(() => {
    detailsAccommodation.init();
})

const detailsAccommodation = {
    $details: null,
    $form: null,
    form: null,
    $arrangementForm: null,
    arrangementForm: null,
    defaults: {
        //objectId: null, 
        unitId: null, 
        guests: null, 
        dates: null,
        dateFrom: null, 
        dateTo: null, 
        objectGroupId: 1,
        calcErrorMessages: null,
    },
    params: {
        objectId: null, 
        unitId: null, 
        guests: null, 
        dates: null,
        dateFrom: null, 
        dateTo: null, 
        objectGroupId: 1,
        calcErrorMessages: null,
    },
    init() {

        this.$details = $('.page-type-details');
        this.params.objectGroupId = this.$details.attr('data-objectgroupid');

        if(! this.$details[0] || this.params.objectGroupId != 1) {
            return;
        }

        this.$form = $('.availability-form');
        this.form = this.$form[0];

        this.$arrangementForm = $('.arrangement-form');
        this.arrangementForm = this.$arrangementForm[0];

        /*if(!this.form && !this.arrangementForm) {
            return;
        }*/

        this.defaults.calcErrorMessages = $('[data-calcErrorMessages]')[0] ? JSON.parse($('[data-calcErrorMessages]').attr('data-calcErrorMessages')) : null;

        this.getParams();
        this.params.objectId = this.$details.attr('data-objectid');

        if(this.form || this.arrangementForm) {
            this.params.dates = this.joinCalendarDates(this.params);
            
            if(this.arrangementForm) {
                this.$arrangementForm.each((index, elem) => {
                    functions.setFormData(elem, this.params);
                });
            }
            if(this.form) {
                functions.setFormData(this.form, this.params);
            }

            this.loader();
            this.getCalculation();
        }

        this.$form.on('submit', e => {
            e.preventDefault();
            let params = functions.getFormData($(e.currentTarget));

            let $alert = $('.alert.availability-form');
            const guests = params.guests;
            const dates = params.dates;
            
            if( !guests || guests.startsWith('0,') || !dates) {
                e.preventDefault();
                $alert.addClass('active');
                
                setTimeout(() => {
                    $alert.removeClass('active');
                }, 3000);
            }
            
            functions.setUrlData(params, true);
        });

        this.$arrangementForm.on('change', e => {
            e.preventDefault();
            let params = functions.getFormData($(e.currentTarget))
            functions.setUrlData(params, true);
        });

        $(window).on('hashchange', e => {

            $('.btn-booking').each((index, elem) => {
                let href = $(elem).attr('data-href');
                $(elem).attr('data-href', href.split('?')[0]);
            });
            //unit-price-total

            this.getParams();
            this.getCalculation();
        });

    },
    splitCalendarDates(dates) {
        if(dates || dates == '') {
            const aDates = dates.split(' - ');
            this.params.dateFrom = aDates[0] ? aDates[0] : null;
            this.params.dateTo = aDates[1] ? aDates[1] : null;
        }
    },
    joinCalendarDates(params) {
        return params.dateFrom && params.dateTo ? params.dateFrom + ' - ' + params.dateTo : null;
    },
    getParams() {
        this.params = details.getParams(this.params, this.defaults);
        if(this.params.dates) {
            this.splitCalendarDates(this.params.dates);
        }
    },
    getCalculation() {
        const calculation = details.getCalculation(this.params);
        if(calculation) {
            this.loader();
            calculation
            .done(response => {
                this.calculationDone(response);
            }).fail(error => {
                console.log(error);
            });
        } else {
            $('.price-from').toggleClass('d-none', false);
            $('.unit-price-paymentType').toggleClass('d-none', false);

            $('.unit-price-discount-title').toggleClass('d-none', false);

            $('.btn-booking-container').toggleClass('d-none', false);

            $('.btn-booking').toggleClass('d-none', true);
            $('.no-period-label').toggleClass('d-none', false);

            $('.unit-error-container').toggleClass('d-none', true);
            
            $('.unit-price-total-label').toggleClass('d-none', true);

            $('.unit').each((index, elem) => {
                const $elem = $(elem);
                const $unitPriceTotal = $elem.find('.unit-price-total');
                const $unitPriceTotalEur = $elem.find('.unit-price-total-eur');
                const $unitPriceOld = $elem.find('.unit-price-old');
                const $unitPriceOldEur = $elem.find('.unit-price-old-eur');

                $unitPriceTotal.text($unitPriceTotal.attr('data-unitPriceTotal'));
                $unitPriceTotalEur.text($unitPriceTotalEur.attr('data-unitPriceTotalEur'));
                let unitPriceOld = $unitPriceOld.attr('data-unitPriceOld');
                $unitPriceOld.text(unitPriceOld && unitPriceOld != '€ 0' ? unitPriceOld : '');
                let unitPriceOldEur = $unitPriceOldEur.attr('data-unitPriceOldEur');
                $unitPriceOldEur.text(unitPriceOldEur && unitPriceOldEur != '0 kn' ? unitPriceOldEur : '');
            })

        }
    },
    isBookable(unitData) {
        if(unitData.booking.status == 'Request') {
            return unitData.calc.message.type !== 'undefined' && unitData.calc.message.type !== 'arrivalDayNotValid' && unitData.calc.message.type !== 'departureDayNotValid' && unitData.calc.status == 'Active';
        }

        return details.isBookable(unitData);
    },
    getCalcErrorMessage(messages, data) {
        if(messages && data.calc.message && data.calc.message.type) {
            return messages[data.calc.message.type] ? messages[data.calc.message.type].replace('{$condition}', (data.calc.message.condition_full ? data.calc.message.condition_full : data.calc.message.condition)) : null;
        }
        else if (data.message && messages && messages[data.message]) {
            return messages[data.message] ? messages[data.message].replace('{$condition}', data.condition) : null;
        }
        return messages ? messages.general : null;
    },
    calculationDone(response) {
        if(response.status) {
            const data = response.data || [];

            //console.log(data);

            Object.entries(data).forEach(unit => {
                const unitId = unit[0];
                const unitData = unit[1];
                const $unit = $('.unit[data-unitid=' + unitId + ']');

                if(this.isBookable(unitData)) {
                    const unitCalc = unitData.calc;
                    const calcItems = unitCalc.items;
                    const total = unitCalc.total;
                    const totalEUR = unitCalc.totalEUR;
                    const currencySymbol = unitCalc.currencySymbol;

                    let priceOld = details.getCalcTotalOld(calcItems);
                    let priceOldEUR = details.getCalcTotalOldEUR(calcItems);
                    let discountTitle = details.getCalcDiscountTitle(calcItems);

                    if(discountTitle) {
                        $unit.find('.unit-price-discount-title').text(discountTitle);
                    }

                    if(priceOld > total) {
                        $unit.find('.unit-price-old').text(currencySymbol + ' ' + functions.formatMoney(priceOld, 0, '.', ','));
                        $unit.find('.unit-price-old-eur').text(functions.formatMoney(priceOldEUR, 0, '.', ',') + ' kn');
                    }

                    $unit.find('.unit-price-total-label').removeClass('d-none');
                    $unit.find('.unit-price-total').text(currencySymbol + ' ' + functions.formatMoney(total, 0, '.', ','));
                    $unit.find('.unit-price-total-eur').text(functions.formatMoney(totalEUR, 0, '.', ','));

                    const $btnBooking = $unit.find('.btn-booking[data-unitId=' + unitId + ']');

                    let bookingLabel = unitData.booking.status == 'Request' ? $btnBooking.attr('data-labelRequest') : $btnBooking.attr('data-labelbook');
                    $btnBooking.text(bookingLabel);

                    if($btnBooking.attr('data-href')) {
                        $btnBooking.attr('data-href',
                            $btnBooking.attr('data-href').split('?')[0] +
                            '?objectGroupId=' + this.params.objectGroupId +
                            '&unitId=' + unitId +
                            '&dateFrom=' + this.params.dateFrom +
                            '&dateTo=' + this.params.dateTo +
                            '&guests=' + this.params.guests +
                            '&addAllOptionals=' + 1
                        );
                    }

                    $btnBooking.on('click', e => {
                        e.preventDefault();
                        if($(e.currentTarget).attr('data-href').split('?')[1]) {
                            location = $(e.currentTarget).attr('data-href');
                        }
                    });
                    

                    /*calcItems.forEach(item => {
                        let title = item.data.text || item.itemTitle;
                        console.log(title);
                        console.log(item.itemTotal);
                        console.log(item.onSpot);
                        console.log(item.optional);
                        console.log(item);
                        console.log('--------------------------------------------------');
                    });*/
                    
                }

                $unit.find('.price-from').toggleClass('d-none', this.isBookable(unitData));
                $unit.find('.unit-price-paymentType').toggleClass('d-none', this.isBookable(unitData));

                $unit.find('.unit-price-discount-title').toggleClass('d-none', ! this.isBookable(unitData));

                $unit.find('.btn-booking-container').toggleClass('d-none', ! this.isBookable(unitData));
                $unit.find('.btn-request-nobookable').toggleClass('d-none', this.isBookable(unitData));

                $unit.find('.btn-booking').toggleClass('d-none', ! this.isBookable(unitData));
                $unit.find('.no-period-label').toggleClass('d-none', this.isBookable(unitData));

                let error = this.getCalcErrorMessage(this.defaults.calcErrorMessages, unitData);
                $unit.find('.unit-error-container').toggleClass('d-none', this.isBookable(unitData));
                $unit.find('.unit-error-container').text(error);
            });
        }
    },
    loader() {
        $('.unit-spinner').addClass('active');
        setTimeout(() => {
            $('.unit-spinner').removeClass('active');
        }, 1000);
    }
};
