$(function () {
    let $tracking = $('.gatracking');

    if ($tracking.length)
    {
        let trackingData = JSON.parse($('[data-gatracking]').attr('data-gatracking'));
        
        if (trackingData) gtag(...trackingData);

    }
    
    if($('.thank-you-page').length) {
        gtag('event', 'klik_na_email', {
            // <event_parameters>
        });
    }
});