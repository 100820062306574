import functions from '../functions.js';

$(function () {
    // select2 templates can be defined as data-template="destinations" ...
    const selectTemplates = {
        destinations: data => {
            if ( !data.element ) {
                return data.text;
            }
            if(data.placeId && data.placeName) {
                //const $element = $(data.element).addClass(data.element.className).text(data.placeName);
                const $element = $('<span>').addClass(data.element.className).text(data.placeName);
                return $element;
            }
            if(data.text) {
                const $element = $('<span>').addClass(data.element.className).text(data.text);
                let elemClass;
                switch ( (data.id).split(':').length ) {
                    case 1: elemClass = 'fw-bold'; break;
                    case 2: elemClass = 'ps-3 fw-bold'; break;
                    case 3: elemClass = 'ps-3 fw-bold'; break;
                    case 4: elemClass = 'ps-4'; break;
                    default: elemClass = 'h5'; break;
                }
                $element.addClass(elemClass);
                return $element;
            }
        },
    };

    // global init select2
    // data-templates
    // data-data
    // data-allow-clear
    // data-nosearch
    // placeholder
    $.map($('.select2'), elem => {
        const $elem = $(elem);
        const config = { dropdownParent: $elem.parent(), language: functions.getLang() };
        config.allowClear = $elem.attr('data-allow-clear');

        // find select2 which has an attribute data-data
        const data = $elem.attr('data-data');
        if ( data ) {
            try {
                config.data = JSON.parse(data);
            } catch (e) {}
        } 
        else if ( $elem.attr('data-special') ) {
            let newData = $('body').attr('data-select-' + $elem.attr('data-special'));
            config.data = JSON.parse(newData);
        }

        if ( $elem.attr('data-template') ) {
            config.templateResult = selectTemplates[$elem.attr('data-template')];
        }
        if ( $elem.attr('data-template-selection') ) {
            config.templateSelection = selectTemplates[$elem.attr('data-template-selection')];
        }

        if ( $elem.attr('data-nosearch') !== undefined ) {
            config.minimumResultsForSearch = Infinity;
        }

        let placeholder = $elem.attr('placeholder');
        $elem.removeAttr('placeholder');
        config.placeholder = placeholder || ' ';
        
        $elem.select2(config)
            .on('select2:clear', () => {
                $elem[0].cleared = true;
            })
            .on('select2:opening', e => {
                if ( $elem[0].cleared ) {
                    e.preventDefault();
                    $elem[0].cleared = false;
                }
            });
    });
});