import functions from "./functions.js";

$(function () {

    const $form = $('.booking-form');
    const form = $form[0];

    if (!form) {
        return;
    }

    const booking = {
        $form: null,
        $payment: null,
        $calculation: null,
        calc: [],
        persons: null,
        promoDiscount: null,
        totalAmount: null,
        totalAmountConverted: null,
        insurance: null,
        status: null,

        init() {

            // insurance
            const $insurance = $('[name="insurance"]');
            if($insurance[0]) {
                $insurance.on('change', e => {
                    const target = e.currentTarget;
                    this.insurance = target.checked ? target.value : null;
                    $('.payment-details').toggleClass('d-none', target.checked);
                    const $btnBooking = $('.btn-booking-form[type="submit"]');
                    const textRequest = $btnBooking.attr('data-textrequest');
                    const textBooking = $btnBooking.attr('data-textbooking');
                    $btnBooking.text(target.checked ? textRequest : textBooking);
                });
            }

            this.status = $('[name="status"]').val();

            // BOOKING PAYMENT TYPE CHECKBOX
            if($('[name="paymentType"]')[0]) {
                checkPaymentType();
                $('[name="paymentType"]').on('change', e => {
                    checkPaymentType();
                });

                function checkPaymentType() {
                    $('[name="paymentType"]').each((index, item) => {
                        const $item = $(item);
                        const id = $item.attr('id');
                        $('[for=' + id + ']').find('[id='  + id + ']').prop('checked', $item.is(':checked'));
                    });
                }
            }

            booking.$form = $(".booking-form");
            booking.$calculation = $(".table-calculation");
            booking.$payment = $(".payment-details");
            booking.params = {};

            booking.$calculation.find("*[data-calc]").each(function (index) {
                let item = JSON.parse($(this).attr('data-calc'));
                item.inCalc = item.isOptional ? 0 : 1;
                booking.calc.push(item);
            });

            booking.$calculation.find('input[type="checkbox"]').change(function (e) {
                let $row = $(this).closest('.calc-item');
                let $quantity = $row.find('[data-quantity]');
                let value = +$quantity.val();
                let checked = $(this).is(':checked') ? 1 : 0;

                if (checked && !value) {
                    $quantity.prop('value', 1);
                } else if (!checked && value) {
                    $quantity.prop('value', 0);
                }

                booking.calculate($(this));
            });

            booking.$calculation.find('[data-quantity]').change(function (e) {
                let $row = $(this).closest('tr');
                let $checkbox = $row.find('input[type="checkbox"]');
                let value = +$(this).val();

                if (value && !$checkbox.is(':checked')) {
                    $checkbox.prop('checked', true);
                } else if (!value && $checkbox.is(':checked')) {
                    $checkbox.prop('checked', false);
                }

                booking.calculate($(this));
            });

            booking.total();

            booking.$form.on('submit', e => {

                if ( booking.$payment.find("[name='paymentType']")[0] && ! booking.$payment.find("[name='paymentType']")[0].checkValidity()) {
                    e.preventDefault();
                    booking.$payment.find("[name='paymentType']")[0].reportValidity()
                }

                if(this.insurance) {
                    const $note = booking.$form.find('[name="note"]');
                    let noteValue = $note.val();
                    noteValue = this.insurance + ' ' + noteValue;
                    $note.val(noteValue);
                }
                $('[name="status"]').val(this.insurance ? 'Request' : this.status);

                booking.submit();
            });

        },
        submit() {

            $.each(booking.calc, function(key, item) {
                if ( ! item.inCalc) {
                    delete booking.calc[key];
                } else if (item.itemDefinitionId.categoryId.key == 'baseRate') {
                    let $guestsPerProduct = $("[data-guests-parentId='" + item.parentId + "']");
                    let guests = [];

                    if ($guestsPerProduct.length) {
                        $guestsPerProduct.find('[data-guests]').each(function (index) {
                            let g = +$(this).data('guests');
                            guests[index] = {
                                firstName : $(this).find("input[name='firstName" + g + "']").val(),
                                lastName : $(this).find("input[name='lastName" + g + "']").val(),
                                age : $(this).find("input[name='age" + g + "']").val()
                            };
                        });
                        booking.calc[key].guestsData = guests;
                    }
                }
            });

            booking.params.paymentType = booking.$payment.find("[name='paymentType']:checked").val() ? booking.$payment.find("[name='paymentType']:checked").val() : 'bankTransfer';
            booking.params.paymentType = this.insurance ? 'bankTransfer' : booking.params.paymentType;
            booking.params.calc = JSON.stringify(booking.calc);
            booking.params.requestId = Math.floor(Math.random() * ((9999999 - 1000000) + 1) + 1000000);

            $.each(booking.params, function (key, param) {
                $('<input>').attr({
                    type: 'hidden',
                    name: key,
                    value: param
                }).appendTo(booking.$form);
            });

        },
        calculate($elem) {
            let $row = $elem.closest('.calc-item');

            let $checkbox = $row.find('input[type="checkbox"]');
            let checked = $checkbox.is(':checked') ? 1 : 0;
            let id = $checkbox.attr('id');

            let $quantity = $row.find('[data-quantity]:visible');
            let quantity = null;
            
            if ($quantity.length) {
                quantity = +$quantity.val();
            }
            
            $.each(booking.calc, function (key, calc) {
                if (calc.id == id) {
                    
                    if (quantity == null) {
                        quantity = booking.calc[key].quantity;
                    }
                    
                    booking.calc[key].inCalc = checked;
                    booking.calc[key].quantity = (quantity || 1);
                    booking.calc[key].total = booking.itemTotal(booking.calc[key]);
                    booking.calc[key].totalConverted = booking.itemTotalConverted(booking.calc[key]);

                    $row.find('.item-total-amount').html(functions.formatMoney(booking.calc[key].total, 2, ',', '.'));
                    if(booking.calc[key].totalConverted) {
                        $row.find('.item-total-amount-converted').html(functions.formatMoney(booking.calc[key].totalConverted, 2, ',', '.'));
                    }
                }
            });


            booking.total();
        },
        itemTotal(item) {
            let itemTotal = 0.00;

            if (item.paymentTypeId.key) {

                if ($.inArray(item.paymentTypeId.key, ['perPerson', 'perPersonPerDay', 'perPersonPerWeek']) > -1) {
                    itemTotal = +item.quantity * +item.price;
                } else {
                    itemTotal = +item.price;
                }
            } else {
                itemTotal = +item.quantity * +item.price;
            }

            return itemTotal;
        },
        itemTotalConverted(item) {
            let itemTotal = 0.00;

            if (item.paymentTypeId.key) {

                if ($.inArray(item.paymentTypeId.key, ['perPerson', 'perPersonPerDay', 'perPersonPerWeek']) > -1) {
                    itemTotal = +item.quantity * +item.priceConverted;
                } else {
                    itemTotal = +item.priceConverted;
                }
            } else {
                itemTotal = +item.quantity * +item.priceConverted;
            }

            return itemTotal;
        },
        total() {
            booking.totalAmount = 0.00;
            booking.totalAmountConverted = 0.00;

            $.each(booking.calc, function (key, calc) {
                if (!calc.onSpot && calc.inCalc) {
                    booking.totalAmount += booking.itemTotal(calc);
                    booking.totalAmountConverted += booking.itemTotalConverted(calc) ? booking.itemTotalConverted(calc) : 0;
                }
            });

            $('.total-amount').html(functions.formatMoney(booking.totalAmount, 2, ',', '.'));
            if(booking.totalAmountConverted) {
                $('.total-amount-converted').html(functions.formatMoney(booking.totalAmountConverted, 2, ',', '.'));
            }

            const advancePaymentPercent = $('[data-advancePaymentPercent]').attr('data-advancepaymentpercent');
            const advance = booking.totalAmount * (advancePaymentPercent / 100);
            const rest = booking.totalAmount - advance;

            $('.advance').html(functions.formatMoney(advance, 2, ',', '.'));
            $('.rest').html(functions.formatMoney(rest, 2, ',', '.'));

            const advanceConverted = booking.totalAmountConverted * (advancePaymentPercent / 100);
            const restConverted = booking.totalAmountConverted - advanceConverted;

            if(advanceConverted) {
                $('.advance-converted').html(functions.formatMoney(advanceConverted, 2, ',', '.'));
            }
            if(restConverted) {
                $('.rest-converted').html(functions.formatMoney(restConverted, 2, ',', '.'));
            }

        }
    }

    booking.init();
});