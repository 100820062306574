import functions from '../functions';
import pagination from '../components/pagination';
import listResults from '../components/listResults';

$(() => {
    list.init();
})

const list = {
    $form: null,
    form: null,
    $filter: null,
    filter: null,
    isHome: false,
    $listItems: null,
    defaults: {},
    params: {},
    init() {
        this.$form = $('.search-form');
        this.form = this.$form[0];

        this.$filter = $('.search-filter');
        this.filter = this.$filter[0];  

        this.$listItems = $('.list-items');
        this.isHome = this.$form.last().attr('data-ishome') || ! this.$listItems.length;

        if(! this.form) {
            return;
        }

        $('.guide-clear').toggleClass('d-none', ! this.params.destination);

        $('.guide-clear').on('click', e => {
            e.preventDefault();
            e.stopPropagation();
            this.params.destination = '';
            this.params.countryId = '';
            $(e.currentTarget).parents('.form-guide-container').find('[name="destination"]').val('').trigger('change');
        });

        $('[name="destination"]').on('change', e => {
            $(e.currentTarget).parents('.form-guide-container').find('.guide-clear').toggleClass('d-none', ! $(e.currentTarget).val());
        });
    },
    getParams(params, defaults = {}, filterParams = {}) {      
        this.params = $.extend(params, defaults, filterParams, functions.getUrlData(true));
        if(params && params.dates) {
            this.splitCalendarDates(params.dates);
        }
        if(params && params.destination) {
            if(params.ski && params.ski != 'false') {
                const destinations = params.destination.split(',');

                let countryIds = [];

                destinations.forEach(destination => {
                    const dest = destination.split(':');
                    
                    this.params.countryId = dest[0] ? this.getDestinationIds(dest[0], countryIds) : this.defaults.countryId;
                    this.params.linkedId = dest[1] ? dest[1] : null;
                    //this.params.destination = null;
                });
            } else {
                this.setDestination(params.destination);
            }
        }

        return this.params;
    },
    splitCalendarDates(dates) {
        if(dates || dates == '') {
            const aDates = dates.split(' - ');
            this.params.dateFrom = aDates[0] ? aDates[0] : null;
            this.params.dateTo = aDates[1] ? aDates[1] : null;
        }
    },
    setDestination(destination) {

        if(destination) {
            const destinations = destination.split(',');

            let countryIds = [],
                regionIds = [],
                rivieraIds = [],
                placeIds = [];

            destinations.forEach(destination => {
                const dest = destination.split(':');
                
                this.params.countryId = dest[0] ? this.getDestinationIds(dest[0], countryIds) : this.defaults.countryId;
                this.params.regionId = dest[1] ? this.getDestinationIds(dest[1], regionIds) : null;
                this.params.rivieraId = dest[2] ? this.getDestinationIds(dest[2], rivieraIds) : null;
                this.params.placeId = dest[3] ? this.getDestinationIds(dest[3], placeIds) : null;
            });
        } else {
            this.params.countryId = this.defaults.countryId;
            this.params.regionId = null;
            this.params.rivieraId = null;
            this.params.placeId = null;
        }
    },
    getDestinationIds(destination, array) {
        if(destination && $.inArray(destination, array)) {
            array.push(destination);
        }
        return array.join();
    },
    find(params) {
        
        this.setLoadingMode();

        return $.get('/services/list/', params);

    },
    findDone(response, params, defaults) {
        response = JSON.parse(response);          

        this.unsetLoadingMode();

        this.total = response.total;

        this.$listItems.html(response.html);
        if (this.total != 0) {

            if($('.paginate')[0]) {
                $('.paginate').html(pagination({
                    currentPage: params._page,
                    total: this.total,
                    perPage: defaults.perPage,
                }));
            }
        }

        listResults.getResults(this.total, params._page, defaults.perPage);

        this.initComponents();

    },
    paginate(page) {
        if (page != this.params._page) {
            this.params._page = page;
        }

        functions.setUrlData(this.params, true);
    },
    setLoadingMode() {
        if(! this.loading) {
            this.loading = true;

            // form submit btn
            (this.$form).find('button').prop('disabled', true);

            if ( ! $('.spinner').length) {
                $('<div class="spinner d-none"><div class="spinner-border text-secondary" role="status" style="width: 4rem; height: 4rem;"><span class="sr-only">Loading...</span></div></div>').insertBefore('.list-items');
            }

            $('.spinner').removeClass('d-none');
            $('.spinner').addClass('d-flex justify-content-center');
            this.$listItems.addClass('d-none');
            $('.paginate').addClass('d-none');
        }
    },
    unsetLoadingMode() {
        if(this.loading) {
            this.loading = false;

            // form submit btn
            (this.$form).find('button').prop('disabled', false);

            $('.spinner').removeClass('d-flex justify-content-center');
            $('.spinner').addClass('d-none');
            this.$listItems.removeClass('d-none');
            $('.paginate').removeClass('d-none');

            //functions.scrollToElement(this.$listItems, 100);
        }
    },
    initComponents() {

        this.$sortBy = $('[name="sortBy"]');
        this.sortBy = this.$sortBy[0] ? this.$sortBy[0] : null;

        if(this.sortBy && this.params.sortBy) {
            this.$sortBy.val(this.params.sortBy);
        }

        this.$sortBy.on('change', e => {
            this.params = $.extend(this.params, this.getParams(), functions.getFormData(this.$sortBy));
            functions.setUrlData(this.params, true, true);
        });

        // register paginate        
        $('[data-page]').on('click', e => {
            e.preventDefault();
            this.paginate(+$(e.currentTarget).attr('data-page'));
        });

    },
}

export {
    list,
};