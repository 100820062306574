import functions from '../functions.js';
import Slider from "../components/Slider";
import { details } from './details';

$(() => {
    detailsTravel.init();
})

const detailsTravel = {
    $details: null,
    $form: null,
    form: null,
    params: {
        objectId: null, 
        unitId: null, 
        guests: null, 
        dates: null,
        dateFrom: null, 
        dateTo: null, 
        objectGroupId: 6,
        calcErrorMessages: null,
    },
    init() {

        this.$details = $('.page-type-details');
        this.params.objectGroupId = this.$details.attr('data-objectgroupid');

        if(! this.$details[0] || this.params.objectGroupId != 6) {
            return;
        }

        this.getParams();

        this.params.objectId = this.$details.attr('data-objectid');
        this.params.calcErrorMessages = JSON.parse($('[data-calcerrormessages]').attr('data-calcerrormessages'));

        this.initDepartureCalendar();
        this.setDefaultDeparture();

        this.setGuestsInstances();
        this.setUnitRates();

        $('.departure').on('click', e => {
            $('.departure').removeClass('active');

            const $target = $(e.currentTarget);
            $target.addClass('active');
            this.params.dateFrom = $target.attr('data-datefrom');
            $('[data-datefrom=' + this.params.dateFrom + ']').addClass('active');
            this.params.dateTo = $target.attr('data-dateto');

            this.printDates();

            functions.setUrlData({'dateFrom': this.params.dateFrom }, true);

        });

        $('[name=guests]').on('change', e => {
            e.preventDefault();
            const $target = $(e.currentTarget);
            this.params.unitId = $target.attr('data-unitid');
            this.params.guests = $target.val();

            this.getCalculation();
        });

        //this.getCalculation();

        $(window).on('hashchange', e => {
            this.getParams();

            this.setGuestsInstances();
            this.setUnitRates();

            $('.unit').each((index, unit) => {
                const $unit = $(unit);
                this.params.unitId = $unit.attr('data-unitid');
                this.params.guests = $unit.find('[name=guests]').val();
                this.getCalculation();
            });
        });

        $('.btn-print').on('click', e => {
            const params = $('.btn-print').attr('data-params');

            if(params) {
                const url = location.protocol + '//' + location.hostname + '/services/pdf/' + params;
                window.open(url, '_blank').focus();
            }
        });

    },
    setUnitRates() {
        if(this.params.dateFrom) {
            $('.unit').addClass('d-none');
            this.loader();

            setTimeout(() => {
                const $departure = $('.departure[data-datefrom=' + this.params.dateFrom + ']');
                const rates = JSON.parse($departure.attr('data-rates'));
                Object.entries(rates).forEach(item => {
                    const unitId = item[0];
                    const rate = item[1];
    
                    const $unit = $('.unit[data-unitid="' + unitId + '"]');
                    $unit.removeClass('d-none');
    
                    $unit.find('.unit-price-old').text(rate.priceWithDiscount ? rate.price : '');
                    $unit.find('.unit-price-total').text(rate.priceWithDiscount ? rate.priceWithDiscount : rate.price);
    
                    $unit.find('.unit-price-old-eur').text(rate.priceWithDiscountEUR ? rate.priceEUR : '');
                    $unit.find('.unit-price-total-eur').text(rate.priceWithDiscountEUR ? rate.priceWithDiscountEUR : rate.priceEUR);
    
                    $unit.find('.fm-discount').toggleClass('d-none', ! rate.discountTitle);
                    $unit.find('.fm-discount-title').text(rate.discountTitle ? rate.discountTitle : '');
                    $unit.find('.fm-discount-valid-until').toggleClass('d-none', ! rate.validUntil);
                    $unit.find('.fm-discount-valid-until-date').text(rate.validUntil ? rate.validUntil : '');

                    // unit order by price
                    let price = rate.priceWithDiscount ? rate.priceWithDiscount : rate.price;
                    price = price.replace('€ ', '');
                    price = price.split(',')[0];
                    price = +price.replace('.', '');
                    $unit.prop('style', 'order: ' + price);
                });
            }, 1000);
        }
    },
    setGuestsInstances() {
        const $departure = $('.departure[data-datefrom=' + this.params.dateFrom + ']');
        let maxPersonsUnits = $departure.attr('data-maxPersonsUnits') ? JSON.parse($departure.attr('data-maxPersonsUnits')) : null;
        
        if(maxPersonsUnits) {
            Object.entries(maxPersonsUnits).forEach(item => {
                const unitId = item[0];
                const quantity = item[1];
    
                $('.input-guests[data-unitid="' + unitId + '"]').each((i, elem) => {
                    const instance = elem._inputGuests; 
                    instance.max = quantity ? quantity : instance.max;
                    instance.checkUpdateBtns(instance, 'adults');
                });
            });
        }
    },
    setMinOccupancy(unitId, unitData) {
        const guests = unitData.calc.guests.split(',');
        const persons = +guests[0] + +guests[1];
        const minOccupancy = +$('[name="guests"][data-unitid="' + unitId + '"]').attr('data-minoccupancy');
        const isBookable = persons % minOccupancy == 0;
        const $unit = $('.unit[data-unitid="' + unitId + '"]');

        $unit.find('.unit-error-container').toggleClass('d-none', isBookable).text('Minimalan broj osoba: ' + minOccupancy);

        $unit.find('.price-from').toggleClass('d-none', isBookable);
        $unit.find('.unit-price').toggleClass('d-none', ! isBookable);

        $unit.find('.unit-price-discount-title').toggleClass('d-none', ! isBookable);

        $unit.find('.btn-booking-container').toggleClass('d-none', ! isBookable);
        $unit.find('.btn-request-nobookable').toggleClass('d-none', isBookable);
    },
    initDepartureCalendar() {
        const $bookingCalendar = $('#booking-calendar-slider');

        if($bookingCalendar[0]) {
            const length = $bookingCalendar.find('.keen-slider__slide').length;
            const perView = 8;

            //let slidesPerView = length < perView ? length + 1 : perView;
            let slidesPerView = length < perView ? length : perView;

            if(length < perView) {
                $('.booking-calendar-wrapper').addClass('col-lg-' + (length + 1));
                $('.booking-calendar-wrapper').addClass('mx-auto');
            }
    
            details.slider = new Slider($bookingCalendar, {
                // mode: 'snap',
                //centered: true,
                duration: 200,
                fade: false,
                loop: false,
                controls: false,
                initial: 0,
                arrows: (! functions.isMobile()) || length > perView,
                slides: {
                    perView: slidesPerView,
                    spacing: 8,
                },
                breakpoints: {
                    '(min-width: 577px) and (max-width: 1024px)': {
                        controls: true,
                        arrows: length > 6,
                        //centered: true,
                        slides: {
                            perView: 6.2,
                        },
                    },
                    '(max-width: 576px)': {
                        controls: true,
                        arrows: length > 2,
                        //centered: true,
                        slides: {
                            perView: 2.1,
                        },
                    },
                },
            });
        }
    },
    setDefaultDeparture() {
        this.params.dateFrom = ! this.params.dateFrom ? $('[data-datefrom]').attr('data-datefrom') : this.params.dateFrom;
        $('[data-datefrom=' + this.params.dateFrom + ']').addClass('active');
        this.params.dateTo = $('[data-datefrom=' + this.params.dateFrom + ']').attr('data-dateto');

        this.params.guests = $('[name=guests]').val(); 

        functions.setUrlData({'dateFrom': this.params.dateFrom, 'guests': this.params.guests }, true);

        $('.departure[data-dateFrom=' + this.params.dateFrom +']').each((index, elem) => {
            //this.printDeparturePriceFrom($(elem));
        });

        this.printDates();
    },
    printDates() {
        $('.departure-date-from').text(functions.formatDate(this.params.dateFrom));
        $('.departure-date-to').text(functions.formatDate(this.params.dateTo));
    },
    getParams() {
        this.params = details.getParams(this.params);
    },
    getCalculation() {

        //console.log(this.params);
        const calculation = details.getCalculation(this.params);
        if(calculation) {
            calculation
                .done(response => {
                    this.calculationDone(response);
                }).fail(error => {
                    console.log(error);
                });
        }
    },
    calculationDone(response) {
        //console.log(response);
        if(response.status) {
            const data = response.data || [];

            //console.log(data);

            Object.entries(data).forEach(unit => {
                const unitId = unit[0];
                const unitData = unit[1];
                const $unit = $('.unit[data-unitid=' + unitId + ']');

                //console.log(details.isBookable(unitData));

                if(details.isBookable(unitData)) {
                    const unitCalc = unitData.calc;
                    const calcItems = unitCalc.items;
                    const total = unitCalc.total;
                    const totalEUR = unitCalc.totalEUR;
                    const currencySymbol = unitCalc.currencySymbol;

                    let priceOld = details.getCalcTotalOld(calcItems);
                    let priceOldEUR = details.getCalcTotalOldEUR(calcItems);
                    let discountTitle = details.getCalcDiscountTitle(calcItems);

                    if(discountTitle) {
                        $unit.find('.unit-price-discount-title').text(discountTitle);
                    }

                    if(priceOld > total) {
                        $unit.find('.unit-price-old').text(currencySymbol + ' ' + functions.formatMoney(priceOld, 0, '.', ','));
                        $unit.find('.unit-price-old-eur').text(functions.formatMoney(priceOldEUR, 0, '.', ','));
                    }

                    $unit.find('.unit-price-total-label').removeClass('d-none');
                    $unit.find('.unit-price-total').text(currencySymbol + ' ' + functions.formatMoney(total, 0, '.', ','));
                    $unit.find('.unit-price-total-eur').text(functions.formatMoney(totalEUR, 0, '.', ','));
                    
                    this.printDates();

                    const $btnBooking = $unit.find('.btn-booking[data-unitId=' + unitId + ']');

                    if($btnBooking.attr('data-href')) {
                        $btnBooking.attr('data-href',
                            $btnBooking.attr('data-href').split('?')[0] +
                            '?objectGroupId=' + this.params.objectGroupId +
                            '&unitId=' + unitId +
                            '&dateFrom=' + this.params.dateFrom +
                            '&dateTo=' + this.params.dateTo +
                            '&guests=' + this.params.guests +
                            '&addAllOptionals=' + 1
                        );
                    }

                    $btnBooking.on('click', e => {
                        e.preventDefault();
                        location = $(e.currentTarget).attr('data-href');
                    });

                    /*calcItems.forEach(item => {
                        let title = item.data.text || item.itemTitle;
                        console.log(title);
                        console.log(item.itemTotal);
                        console.log(item.onSpot);
                        console.log(item.optional);
                        console.log(item);
                        console.log('--------------------------------------------------');
                    });*/
                    
                } else {
                    
                }
                
                $unit.find('.price-from').toggleClass('d-none', details.isBookable(unitData));
                $unit.find('.unit-price-total-label').toggleClass('d-none', ! details.isBookable(unitData));
                $unit.find('.unit-payment-key').toggleClass('d-none', details.isBookable(unitData));
                //$unit.find('.unit-price').toggleClass('d-none', ! details.isBookable(unitData));

                $unit.find('.unit-price-discount-title').toggleClass('d-none', ! details.isBookable(unitData));

                //console.log(! details.isBookable(unitData));
                $unit.find('.btn-booking-container').toggleClass('d-none', ! details.isBookable(unitData));
                $unit.find('.btn-request-nobookable').toggleClass('d-none', details.isBookable(unitData));

                details.isBookable(unitData) && this.setMinOccupancy(unitId, unitData);

                if($unit.find('.unit-error-container').hasClass('.d-none')) {
                    const guests = unitData.calc.guests.split(',');
                    const persons = +guests[0] + +guests[1];
                    $unit.find('.unit-error-container').toggleClass('d-none', details.isBookable(unitData) && persons <= unitData.quantity).text('Maximalno osoba: ' + unitData.quantity);
                }
            });
        }
    },
    loader() {
        $('.unit-spinner').addClass('active');
        setTimeout(() => {
            $('.unit-spinner').removeClass('active');
        }, 1000);
    }
};
